@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap');

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

html, body {
  min-width: 320px;
}

#root {
  min-height: 100%; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

@keyframes herobg {
  0%, 100% {
    transform: scale(0.98);
  }
  50% {
    transform: scale(1);
  }
}

@keyframes heromid {
  0%,100% {
    top: 56px
  }
  50% {
    top: 58px
  }
}

@keyframes herofore {
  0% {
    transform: rotate(-2deg);
  }
  50% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}
